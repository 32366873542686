import React, { useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import StepNews from "../components/StepNews";
import { ContainerL } from "../ui/containers";
import Card from "../components/Cards";
import Modal from "../ui/modal";
import '../components/layout.css'



const NewsPage = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const firstData = props.data.allStrapiArticle.nodes[0];
  const AllData = props.data.allStrapiArticle.nodes;
  const { pageContext } = props;
  const firstNews = {
    title: firstData.title,
    text: firstData.description.substr(0, 329) +"...",
    imgUrl: firstData.image.localFile,
    date: firstData.published_at,
    link: firstData.id

  }
  const toggleOpenModal = () => {
    if (!openModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setOpenModal(!openModal);
  };
  return (
    <>
      <Seo title={"News"} />
      <Header />
      {
        openModal ?
          (<Modal toggleOpenModal={toggleOpenModal} />)
          :
          (
            <>
              <ContainerL>
                <Otstup></Otstup>
                <Title>
                  <div style={{ fontWeight: "bold" }}>Что у нас</div>
                  нового
                </Title>
                <Card plus={true} link gutter isReversed={false} data={firstNews}/>
              </ContainerL>
              <ContainerL>
                <BackgroundText>
                  будь всегда <br /> в теме
                </BackgroundText>
              </ContainerL>

              <ContainerL style={{ margin: "160px auto" }}>
                 <StepNews pageContext={pageContext} DATA={AllData}/>
              </ContainerL>
              <Footer toggleOpenModal={toggleOpenModal} />
            </>
          )}

    </>
  );
};

export default NewsPage;

const Otstup = styled.div`
  margin-top: 120px;
  @media (max-width: 568px) {
    margin-top: 45px;
  }
`;

const Title = styled.div`
  font-weight: 200;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 568px) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 35px;
  }
`;

const BackgroundText = styled.div`
  font-size: 288px;
  position: absolute;
  color: #0D0C0C;
  margin-left: 335px;
  margin-top: -432px;
  text-transform: uppercase;
  z-index: -9;
  font-weight: 300;
  line-height: 320px;
  text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.55);

  &::selection {
    background: transparent;
    color: #0D0C0C;
  }

  &::-moz-selection {
    background: transparent;
    color: #0D0C0C;
  }

  @media (max-width: 1280px) {
    font-size: 200px;
    margin-left: 0;
    line-height: 250px;
    margin-top: -220px;
  }
  @media (max-width: 1000px) {
    font-size: 120px;
    margin-left: 370px;
    line-height: 120px;
    margin-top: -162px;
  }
  @media (max-width: 768px) {
    font-size: 100px;
    color: #131212;
    margin-left: 210px;
    line-height: 100px;
    margin-top: -144px;
  }
  @media (max-width: 550px) {
    font-size: 72px;
    line-height: 86px;
    margin-left: 150px;
    margin-top: -145px;
  }
  @media (max-width: 435px) {
    margin-left: 15px;
    margin-top: -135px;
  }

`;

export const query = graphql`
    query NewsQuery ($skip: Int!, $limit: Int!){
        allStrapiArticle(sort: {order: DESC, fields: published_at} limit: $limit skip: $skip) {
            nodes {
                title
                description
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
                id
                published_at(formatString: "d MMMM yyyy", locale: "ru")
            }
        }
    }
`;

