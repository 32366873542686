import React, { useState } from "react";
import styled from "styled-components";
import Arrow from "../../assets/ArrowAnt.svg";
import Arrowright from "../../assets/arrowblueright.svg";
import Arrowleft from "../../assets/arrowblueleft.svg";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const New = ({ isMobile = false, id, index, image, title, published_at, description }) => {
  const img = getImage(image);
  return (
    <ContainerNews id={"news"} key={index} orientation={index % 2 === 0 ? "left" : "right"}>
      <Img image={img} alt={"image " + title} />

      {
        isMobile ?
          <NameText>{title}</NameText>
          :
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <NameText>{title}</NameText>
            <DateText>{published_at}</DateText>
          </div>
      }

      <DescrText>
        {description.substr(0, 100) + "..."}
      </DescrText>
      <Btn to={"/articles/" + id}>
        <div style={{ display: "flex", alignItems: "center" }}><Span>Подробней</Span>
          <Arrow />
        </div>
      </Btn>
    </ContainerNews>
  );
}

const MAX_PAGES = 4;
const StepNews = (props) => {
  const { pageContext } = props;
  const [page, setPage] = useState(0);
  console.log(pageContext)

  let navs = Array.apply(null, Array(pageContext.numPages));
  if(navs > MAX_PAGES) navs = [1, 2, 3, 4];
  let skip = Math.ceil(page * pageContext.limit  / 4);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Deskctop>
          {props.DATA.map((item, index) =>
            <New
              key={item.id}
              id={item.id}
              index={index}
              image={item.image.localFile}
              title={item.title}
              published_at={item.published_at}
              description={item.description}
            />
          )}
        </Deskctop>
        <Mobile>
          {props.DATA.map((item, index) =>
            <New
              key={item.id}
              isMobile
              id={item.id}
              index={index}
              image={item.image.localFile}
              title={item.title}
              published_at={item.published_at}
              description={item.description}
            />
          )}
        </Mobile>
      </div>
      <Container>
        <>
          {
            navs.map((item, ind) =>
              <Step
                key={ind}
                style={{ backgroundColor: pageContext.currentPage === (ind + 1 + skip) ? "#4285F4" : "transparent" }}
                to={(ind + 1 + skip) === 1 ? "/news" : `/news/${(ind + 1 + skip)}#news`}
              >
                {ind + 1 + skip}
              </Step>
            )
          }
        </>

        <ArrowChange>
          <BtnStep
            disabled={page === 0}
            onClick={() => setPage(page - 1)}
          >
            <Arrowleft style={{ marginTop: 0 }} />
          </BtnStep>
          <BtnStep
            disabled={!(pageContext.numPages > pageContext.currentPage && pageContext.numPages > MAX_PAGES) }
            onClick={() => setPage(page + 1)}
          >
            <Arrowright style={{ marginTop: 0 }} />
          </BtnStep>
        </ArrowChange>


      </Container>
    </>
  );
};

export default StepNews;

const Img = styled(GatsbyImage)`
  border-radius: 18px;
  background-size: cover;
  width: 620px;
  height: 400px;
  opacity: 0.5;
  @media (max-width: 1300px) {
    width: 420px;
    height: 350px;
  }
  @media (max-width: 1000px) {
    width: 330px;
    height: 300px;
  }
  @media (max-width: 700px) {
    width: 210px;
  }
  @media (max-width: 450px) {
    width: 100%;
    height: 200px;
  }
`;

const ContainerNews = styled.div`
  color: white;
  margin-right: ${props => props.orientation === "left" ? 640 : 0}px;
  margin-left: ${props => props.orientation === "right" ? 640 : 0}px;
  max-width: 620px;
  margin-top: ${props => props.orientation === "right" ? -370 : 0}px;
  @media (max-width: 1300px) {
    margin-right: ${props => props.orientation === "left" ? 450 : 0}px;
    margin-left: ${props => props.orientation === "right" ? 450 : 0}px;
    max-width: 420px;
  }
  @media (max-width: 1000px) {
    margin-right: ${props => props.orientation === "left" ? 390 : 0}px;
    margin-left: ${props => props.orientation === "right" ? 390 : 0}px;
    max-width: 330px;
  }
  @media (max-width: 700px) {
    margin-right: ${props => props.orientation === "left" ? 250 : 0}px;
    margin-left: ${props => props.orientation === "right" ? 250 : 0}px;
    max-width: 210px;
  }
  @media (max-width: 450px) {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 80px;
  }
`;

const NameText = styled.div`
  max-width: 380px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 55px;
  font-size: 18px;
  line-height: 27px;
  @media (max-width: 450px) {
    margin-top: 50px;
    font-size: 16px;
  }
`;

const DescrText = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  margin-top: 31px;
  @media (max-width: 450px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Btn = styled(Link)`
  background-color: inherit !important;
  color: #373737 !important;
  border: none;
  display: flex;
  align-items: center;
  max-width: 130px;
  margin-top: 31px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #373737;
`;

const Span = styled.span`
  margin-right: 17px;
  font-weight: 500;
  font-size: 16px;
  line-height: 46px;
  transition: color 0.2s;
  &:hover {
    color: white;
  }
`;

const Step = styled(Link)`
  width: 60px;
  height: 60px;
  background-color: inherit;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  @media (max-width: 450px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 100px;
  padding-top: 132px;
`;

const ArrowChange = styled.div`
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BtnStep = styled(Link)`
  background-color: inherit;
  :hover {
    & > svg > path {
      fill: #ffffff;
    }
  }
`;

const Deskctop = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    display: none;
  }
`;
const Mobile = styled.div`
  display: none;
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }
  display: none;
`;

const DateText = styled.div`
  white-space: nowrap;
  margin-top: 55px;
  color: #636363;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 23px;
  }
  @media (max-width: 450px) {
    display: none;
  }
`;
